import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import {
  TypeLandingPageTemplate,
  TypeBlogPostSkeleton,
  TypeBuildingBlock,
  isTypeBuildingBlock,
  TypeLink,
  TypeLandingPageTemplateSkeleton,
} from '../../@types/generated';
import { sendEvent, EVENT_NAMES, sendEventProxy } from './../utils/analytics';
import { getEntries } from './../utils/api/contentful';
import { spacing, colors, breakpointQueries } from './../utils/styleguide';
import withAppProps, { WithPageAppProps } from './../utils/withAppProps';
import FundPicker from '../common-components/component-library/FundPicker';
import GhostButton from '../common-components/component-library/buttons/GhostButton';
import MainHero from '../common-components/component-library/heroes/MainHero';
import CTASectionLayout from '../common-components/component-library/layout/CTASectionLayout';
import FormCTA from '../common-components/component-library/layout/FormCTA';
import HighlightAccordionLayout from '../common-components/component-library/layout/HighlightAccordionLayout';
import HighlightLayout, {
  type HighlightButton,
  toHighlightButtons,
} from '../common-components/component-library/layout/HighlightLayout';
import PartnerLogosLayout from '../common-components/component-library/layout/PartnerLogosLayout';
import ArticleCarouselLayout from '../common-components/component-library/layout/articleCarouselLayout/ArticleCarouselLayout';
import PartnerCasesLayout from '../common-components/component-library/layout/partnerCasesLayout/PartnerCasesLayout';
import RichText from '../common-components/contentful-elements/RichText/RichText';
import { transformMetaHeaderType } from '../common-components/contentful-elements/MetaHeader';
import { useMediaQuery } from '../utils/hooks/useMediaQuery';
import { type ImageProps } from '../common-components/component-library/Image';
import { Document } from '@contentful/rich-text-types';

type StartPageProps = {
  identifier: string;
  heroCTAs?: {
    identifier?: string;
    externalUrl?: string;
  }[];
  heroSectionTitle?: string;
  heroParagraph?: string;
  heroImages: {
    landscapeUrl?: string;
    landscapeAlt?: string;
    portraitUrl?: string;
    portraitAlt?: string;
  };
  partnerLogosTitle?: string;
  partnerLogos?: {
    link?: string;
    image?: ImageProps;
  }[];
  highlightAccordionSectionTitle?: string;
  highlightAccordionItems?: {
    title?: string;
    description?: Document;
    button: {
      href?: string;
      label?: string;
    };
    images?: {
      url?: string;
      title?: string;
      description?: string;
    }[];
  }[];
  thoughtLeadershipSectionTitle?: string;
  thoughtLeadershipText?: Document;
  thoughtLeadershipButtons?: HighlightButton[];
  thoughtLeadershipImage: {
    url?: string;
    alt?: string;
    credit?: string;
    fit?: 'cover' | 'contain';
  };
  ctaSectionTitle?: string;
  ctaSectionText?: string;
  ctaSectionButtons?: {
    label?: string;
    externalUrl?: string;
  }[];
  ctaSectionBackgroundImage: {
    url?: string;
    alt?: string;
  };
  qaSectionTitle?: string;
  qaText?: Document;
  qaButtons?: HighlightButton[];
  qaSectionImage: {
    url?: string;
    alt?: string;
    credit?: string;
    fit?: 'cover' | 'contain';
  };
  platformSectionTitle?: string;
  platformText?: Document;
  platformButtons?: HighlightButton[];
  platformImage: {
    url?: string;
    alt?: string;
    credit?: string;
    fit?: 'cover' | 'contain';
  };
  fundPickerSectionTitle?: string;
  fundPickerFunds?: {
    title?: string;
    description?: string;
    imageUrl?: string;
    imageAlt?: string;
    awardsAndStandards?: {
      link?: string;
      url?: string;
      alt?: string;
      fileName?: string;
    }[];
    slug?: string;
  }[];
  contactUsSectionTitle?: string;
  contactUsTitle?: string;
  contactUsSections?: {
    title?: string;
    description?: Document;
    link?: {
      title?: string;
      url?: string;
    };
  }[];
  partnerCasesSectionTitle?: string;
  partnerCases?: {
    logo?: string;
    logoAlt: string;
    imageUrl?: string;
    imageAlt: string;
    text?: Document;
    link?: {
      title?: string;
      url?: string;
    };
  }[];
  articles: {
    title?: string;
    publishedAt: string;
    slug: string;
    externalLink?: string;
    image: {
      url?: string;
      alt: string;
      height: number;
      width: number;
    };
  }[];
};

function StartPage(props: StartPageProps) {
  const {
    query: { utm_source, utm_content, utm_referrer, utm_campaign, utm_medium },
  } = useRouter();

  useEffect(() => {
    sendEvent({
      name: EVENT_NAMES.viewHomePage,
    });
    sendEventProxy({
      name: EVENT_NAMES.viewHomePageProxy,
      properties: undefined,
    });

    if (utm_campaign) {
      sendEvent({
        name: EVENT_NAMES.viewCampaign,
        properties: {
          'campaign name': utm_campaign,
          referrer: document.referrer,
          'utm source': utm_source,
          'utm content': utm_content,
          'utm referrer': utm_referrer,
          'utm campaign': utm_campaign,
          'utm medium': utm_medium,
        },
      });
    }
  }, [utm_source, utm_content, utm_referrer, utm_campaign, utm_medium]);

  const {
    ctaSectionBackgroundImage,
    ctaSectionButtons,
    ctaSectionText,
    ctaSectionTitle,
    heroCTAs,
    heroImages,
    heroParagraph,
    heroSectionTitle,
    highlightAccordionItems,
    highlightAccordionSectionTitle,
    partnerLogos,
    partnerLogosTitle,
    platformButtons,
    platformImage,
    platformSectionTitle,
    platformText,
    qaButtons,
    qaSectionImage,
    qaSectionTitle,
    qaText,
    fundPickerSectionTitle,
    fundPickerFunds,
    thoughtLeadershipButtons,
    thoughtLeadershipImage,
    thoughtLeadershipSectionTitle,
    thoughtLeadershipText,
    contactUsSectionTitle,
    contactUsTitle,
    contactUsSections,
    partnerCasesSectionTitle,
    partnerCases,
    articles,
  } = props;

  const decoratedHighlightAccordionItems = highlightAccordionItems?.map(
    ({ description, title, button, images }) => ({
      title,
      expandedContent: (
        <>
          <RichText document={description} withCheckmark />
          {button.href && (
            <GhostButton
              href={button.href}
              label={button.label}
              css={{ marginTop: `${spacing[4]}px` }}
              color={colors.purple400}
              hideUnderline
              trailingIconName="chevron-right"
            />
          )}
        </>
      ),
      images,
    }),
  );

  const [isTabletOrLarger, determined] = useMediaQuery(breakpointQueries.tablet);
  const orientedHeroImage =
    !isTabletOrLarger && determined && heroImages?.portraitUrl
      ? {
          url: heroImages?.portraitUrl,
          alt: heroImages?.portraitAlt,
        }
      : {
          url: heroImages?.landscapeUrl,
          alt: heroImages?.landscapeAlt,
        };

  return (
    <>
      <MainHero
        sectionTitle={heroSectionTitle}
        paragraph={heroParagraph}
        imageUrl={orientedHeroImage.url}
        backgroundImageAlt={orientedHeroImage.alt}
        primaryButtonLabel={heroCTAs?.[0]?.identifier}
        primaryButtonHref={heroCTAs?.[0]?.externalUrl}
        secondaryButtonLabel={heroCTAs?.[1]?.identifier}
        secondaryButtonHref={heroCTAs?.[1]?.externalUrl}
      />

      <PartnerLogosLayout
        title={partnerLogosTitle}
        logosList={partnerLogos}
        backgroundColor={colors.grey50}
      />

      <HighlightAccordionLayout
        sectionTitle={highlightAccordionSectionTitle}
        items={decoratedHighlightAccordionItems}
      />

      <FundPicker
        sectionTitle={fundPickerSectionTitle}
        impactFunds={fundPickerFunds}
        backgroundColor={colors.grey50}
      />

      <HighlightLayout
        sectionTitle={thoughtLeadershipSectionTitle}
        text={thoughtLeadershipText}
        buttons={thoughtLeadershipButtons}
        buttonVariant="text"
        images={[
          {
            imageUrl: thoughtLeadershipImage?.url,
            imageAlt: thoughtLeadershipImage?.alt,
            imageText: thoughtLeadershipImage?.credit,
            objectFit: thoughtLeadershipImage?.fit,
          },
        ]}
      />

      <CTASectionLayout
        sectionTitle={ctaSectionTitle}
        title={ctaSectionText}
        buttons={ctaSectionButtons}
        backgroundImageUrl={ctaSectionBackgroundImage.url}
        backgroundImageAlt={ctaSectionBackgroundImage.alt}
      />

      <PartnerCasesLayout
        sectionTitle={partnerCasesSectionTitle}
        partnerCases={partnerCases}
        backgroundColor={colors.grey50}
      />

      <HighlightLayout
        sectionTitle={qaSectionTitle}
        text={qaText}
        buttons={qaButtons}
        buttonVariant="text"
        images={[
          {
            imageUrl: qaSectionImage.url,
            imageAlt: qaSectionImage.alt,
            imageText: qaSectionImage.credit,
            objectFit: qaSectionImage.fit,
          },
        ]}
      />

      <HighlightLayout
        sectionTitle={platformSectionTitle}
        text={platformText}
        buttons={platformButtons}
        buttonVariant="text"
        images={[
          {
            imageUrl: platformImage.url,
            imageAlt: platformImage.alt,
            imageText: platformImage.credit,
            objectFit: platformImage.fit,
          },
        ]}
        backgroundColor={colors.grey50}
      />

      <ArticleCarouselLayout sectionTitle={'Articles'} articles={articles} />

      <FormCTA
        ctaFormSectionTitle={contactUsSectionTitle}
        ctaFormTitle={contactUsTitle}
        ctaFormSections={contactUsSections?.map((section) => ({
          ...section,
          renderDescription: () => <RichText document={section?.description} />,
        }))}
        backgroundColor={colors.grey50}
      />
    </>
  );
}

export type ServerSideProps = {
  props: StartPageProps;
} & WithPageAppProps;

export async function getLandingPageServerSideProps(
  page: TypeLandingPageTemplate,
): Promise<ServerSideProps> {
  const contentfulLatestArticles = await getEntries<TypeBlogPostSkeleton>({
    content_type: 'blogPost',
    'fields.availableFor': 'Main web',
    limit: 9,
    order: ['-fields.publishedAt'],
  });

  return {
    props: {
      identifier: page.fields.identifier,
      heroCTAs: page.fields.heroCTAs?.map((cta) => ({
        identifier: cta?.fields.identifier,
        externalUrl: cta?.fields.externalUrl,
      })),
      heroImages: {
        landscapeUrl: page.fields.heroImage?.fields.imageLandscape?.fields.file?.url,
        landscapeAlt: page.fields.heroImage?.fields.imageLandscape?.fields.description,
        portraitUrl: page.fields.heroImage?.fields.imagePortrait?.fields.file?.url,
        portraitAlt: page.fields.heroImage?.fields.imagePortrait?.fields.description,
      },
      heroParagraph: page.fields.heroParagraph,
      heroSectionTitle: page.fields.heroSectionTitle,
      partnerLogosTitle: page.fields.partnerLogosTitle,
      partnerLogos: page.fields.partnerLogos?.map((partnerLogo) => ({
        link: partnerLogo?.fields?.link,
        image: {
          url: partnerLogo?.fields?.image?.fields.file?.url ?? '',
          name: partnerLogo?.fields?.image?.fields.file?.fileName,
          alt:
            partnerLogo?.fields.image?.fields.description ??
            partnerLogo?.fields.image?.fields.title ??
            '',
        },
      })),
      highlightAccordionSectionTitle: page.fields.highlightAccordionSectionTitle,
      highlightAccordionItems: page.fields.highlightAccordionItems
        ?.filter((item): item is TypeBuildingBlock => !!item && isTypeBuildingBlock(item))
        .map((item) => ({
          title: item.fields.title ? documentToPlainTextString(item.fields.title) : undefined,
          description: item.fields.description,
          button: {
            href: item.fields.button?.fields.link?.fields.externalUrl,
            label: item.fields.button?.fields.label,
          },
          images: item.fields.images?.map((image) => ({
            url: image?.fields.file?.url,
            title: image?.fields.title,
            description: image?.fields.description,
          })),
        })),
      thoughtLeadershipSectionTitle: page.fields.thoughtLeadershipSectionTitle,
      thoughtLeadershipText: page.fields.thoughtLeadershipText,
      thoughtLeadershipButtons: toHighlightButtons(page.fields.thoughtLeadershipButtons),
      thoughtLeadershipImage: {
        url: page.fields.thoughtLeadershipImage?.fields.imageLandscape?.fields.file?.url,
        alt: page.fields.thoughtLeadershipImage?.fields.imageLandscape?.fields.description,
        credit: page.fields.thoughtLeadershipImage?.fields.credit,
        fit: page.fields.thoughtLeadershipImageFit,
      },
      ctaSectionTitle: page.fields.ctaSectionTitle,
      ctaSectionText: page.fields.ctaSectionText,
      ctaSectionButtons: page.fields.ctaSectionButtons
        ?.filter((button): button is TypeLink => !!button)
        .map((button) => ({
          label: button.fields.identifier,
          href: button.fields.externalUrl,
        })),
      ctaSectionBackgroundImage: {
        url: page.fields.ctaSectionBackgroundImage?.fields.file?.url,
        alt: page.fields.ctaSectionBackgroundImage?.fields.description,
      },
      qaSectionTitle: page.fields.qaSectionTitle,
      qaText: page.fields.qaText,
      qaButtons: toHighlightButtons(page.fields.qaButtons),
      qaSectionImage: {
        url:
          page.fields.qaSectionImage?.fields.imageLandscape?.fields.file?.url ??
          page.fields.qaSectionImage?.fields.imagePortrait?.fields.file?.url,
        alt:
          page.fields.qaSectionImage?.fields.imageLandscape?.fields.description ??
          page.fields.qaSectionImage?.fields.imagePortrait?.fields.description,
        credit: page.fields.qaSectionImage?.fields.credit,
        fit: page.fields.qaSectionImageFit,
      },
      platformSectionTitle: page.fields.platformSectionTitle,
      platformText: page.fields.platformText,
      platformButtons: toHighlightButtons(page.fields.platformButtons),
      platformImage: {
        url:
          page.fields.platformImage?.fields.imageLandscape?.fields.file?.url ??
          page.fields.platformImage?.fields.imagePortrait?.fields.file?.url,
        alt:
          page.fields.platformImage?.fields.imageLandscape?.fields.description ??
          page.fields.platformImage?.fields.imagePortrait?.fields.description,
        credit: page.fields.platformImage?.fields.credit,
        fit: page.fields.platformImageFit,
      },
      fundPickerSectionTitle: page.fields.fundPickerSectionTitle,
      fundPickerFunds: page.fields.fundPickerFunds?.map((fund) => ({
        title: fund?.fields.title,
        description: fund?.fields.description,
        imageUrl:
          fund?.fields.topMedia?.fields.imageLandscape?.fields.file?.url ??
          fund?.fields.topMedia?.fields.imagePortrait?.fields.file?.url,
        imageAlt:
          fund?.fields.topMedia?.fields.imageLandscape?.fields.description ??
          fund?.fields.topMedia?.fields.imagePortrait?.fields.description,
        awardsAndStandards: fund?.fields.awardsAndStandards?.map((award) => ({
          link: award?.fields.link,
          url: award?.fields.image?.fields.file?.url,
          alt: award?.fields.image?.fields.description,
          fileName: award?.fields.image?.fields.file?.fileName,
        })),
        slug: fund?.fields.slug,
      })),
      contactUsSectionTitle: page.fields.contactUsSectionTitle,
      contactUsTitle: page.fields.contactUsTitle,
      contactUsSections: page.fields.contactUsSections?.map((section) => ({
        title: section?.fields.title,
        description: section?.fields.description,
        link: {
          title: section?.fields.link?.[0]?.fields.identifier,
          url: section?.fields.link?.[0]?.fields.externalUrl,
        },
      })),
      partnerCasesSectionTitle: page.fields.partnerCasesSectionTitle,
      partnerCases: page.fields.partnerCases?.map((partnerCase) => ({
        logo: partnerCase?.fields.mediaLogo?.fields.imageLandscape?.fields.file?.url,
        logoAlt:
          partnerCase?.fields.mediaLogo?.fields.imageLandscape?.fields.description ??
          partnerCase?.fields.mediaLogo?.fields.imageLandscape?.fields.title ??
          '',
        imageUrl:
          partnerCase?.fields.mediaBackground?.fields.orientedMedia?.fields.imageLandscape?.fields
            .file?.url,
        imageAlt:
          partnerCase?.fields.mediaBackground?.fields.orientedMedia?.fields.imageLandscape?.fields
            .description ??
          partnerCase?.fields.mediaBackground?.fields.orientedMedia?.fields.imageLandscape?.fields
            .title ??
          '',
        text: partnerCase?.fields.body,
        link: {
          title: partnerCase?.fields.link?.fields.identifier,
          url: partnerCase?.fields.link?.fields.externalUrl,
        },
      })),
      articles: contentfulLatestArticles.items.map((article) => ({
        title: article.fields.title,
        publishedAt: article.fields.publishedAt,
        slug: article.fields.slug,
        externalLink: article.fields.externalLink,
        image: {
          url: article.fields.image?.fields.file?.url,
          alt: article.fields.image?.fields.description ?? '',
          height: article.fields.image?.fields.file?.details?.image?.height ?? 0,
          width: article.fields.image?.fields.file?.details?.image?.width ?? 0,
        },
      })),
    },
    pageAppProps: {
      metaHeader: transformMetaHeaderType({ metaHeader: page.fields.metaHeader }),
    },
  };
}

export const getServerSideProps = withAppProps(async () => {
  const { items: landingPages } = await getEntries<TypeLandingPageTemplateSkeleton>({
    content_type: 'landingPageTemplate',
    include: 10,
  });
  const [page] = landingPages;

  if (!page) throw new Error('Page not found');

  return await getLandingPageServerSideProps(page);
});

StartPage.layoutProps = {
  initiallyTransparentHeader: true,
  floatingHeader: true,
};

export default StartPage;
