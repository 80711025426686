import styled from '@emotion/styled';
import { breakpoints, spacing } from '../../../../utils/styleguide';
import AccordionItem from './AccordionItem';
import { ReactNode, useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';

const AccordionsWrapper = styled.div`
  ${breakpoints.desktop} {
    padding: 0 ${spacing[5]}px;
  }
`;

const ExpandedContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[8]}px;
  width: 100%;
`;

export type AccordionItem = {
  title?: string;
  expandedContent: ReactNode;
};

export default function AccordionLayout({
  items,
  isFaq = false,
  onChange,
  singleMode = false,
}: {
  items?: AccordionItem[];
  isFaq?: boolean;
  onChange?: (expandedIndexes: number[]) => void;
  singleMode?: boolean;
}) {
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>(singleMode ? [0] : []);

  // Close all accordions when navigating to a new page
  // Only if NOT in single mode
  const pathname = usePathname();
  useEffect(() => {
    if (!singleMode) {
      setExpandedIndexes([]);
    }
  }, [pathname, singleMode]);

  function handleOnClick(index: number) {
    if (expandedIndexes.includes(index)) {
      const newIndexes = expandedIndexes.filter((i) => i !== index);
      setExpandedIndexes(newIndexes);
      onChange && onChange(newIndexes);
      return;
    }

    const newIndexes = singleMode ? [index] : [...expandedIndexes, index];
    setExpandedIndexes(newIndexes);
    onChange && onChange(newIndexes);
  }

  if (!items) return null;

  return (
    <AccordionsWrapper
      itemScope={isFaq ? true : undefined}
      itemType={isFaq ? 'https://schema.org/FAQPage' : undefined}
    >
      {items.map((item, index) => (
        <div
          key={index}
          itemScope={isFaq ? true : undefined}
          itemProp={isFaq ? 'mainEntity' : undefined}
          itemType={isFaq ? 'https://schema.org/Question' : undefined}
        >
          <AccordionItem
            isExpanded={expandedIndexes.includes(index)}
            onClick={
              singleMode && expandedIndexes.includes(index) ? undefined : () => handleOnClick(index)
            }
            title={item.title ?? ''}
            expandedContent={
              <ExpandedContentWrapper>
                <div
                  itemScope={isFaq ? true : undefined}
                  itemProp={isFaq ? 'acceptedAnswer' : undefined}
                  itemType={isFaq ? 'https://schema.org/Answer' : undefined}
                >
                  {item.expandedContent}
                </div>
              </ExpandedContentWrapper>
            }
            isFaq={isFaq}
          />
        </div>
      ))}
    </AccordionsWrapper>
  );
}
