import styled from '@emotion/styled';

import { breakpoints, spacing } from '../../../../utils/styleguide';
import SectionTitle from '../../SectionTitle';
import Carousel from '../../carousel/Carousel';
import Container from '../Container';
import ArticleCarouselCard from './ArticleCarouselCard';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: ${spacing[2]}px;
  margin-top: ${spacing[5]}px;

  ${breakpoints.desktop} {
    padding: 0 ${spacing[5]}px;
  }
`;

export default function ArticleCarouselLayout({
  sectionTitle,
  articles,
  backgroundColor,
}: {
  sectionTitle?: string;
  articles?: {
    title?: string;
    publishedAt: string;
    slug: string;
    externalLink?: string;
    image: {
      url?: string;
      alt: string;
      height: number;
      width: number;
    };
  }[];
  backgroundColor?: string;
}) {
  if (!articles?.length) return null;

  return (
    <Container backgroundColor={backgroundColor}>
      {sectionTitle && <SectionTitle title={sectionTitle} />}

      <ContentWrapper>
        {
          <Carousel differentNrOfSlidesPerBreakpoint maxNrOfSlides={3}>
            {articles.map((article, index) => (
              <ArticleCarouselCard key={index} article={article} />
            ))}
          </Carousel>
        }
      </ContentWrapper>
    </Container>
  );
}
